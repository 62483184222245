import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { PublicGuard } from './guards/public.guard';


const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'public',
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
    canActivate: [PublicGuard]
  },
  {
    path: 'solutions',
    loadChildren: () => import('./solution/solution.module').then(m => m.SolutionModule)
  },
  {
    path: 'quest',
    loadChildren: () => import('./quest/quest.module').then(m => m.QuestModule)
  },
  {
    path: 'coi',
    loadChildren: () => import('./quest-two/quest-two.module').then(m => m.QuestTwoModule)
  },
  {
    path: 'q-test',
    loadChildren: () => import('./quest-three/quest-three.module').then(m => m.QuestThreeModule)
  },
  {path: '**', redirectTo: 'auth'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
