import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CreateProgramRequestBody, TableRequestBody, UpdateProgramRequestBody } from '../../models/local';
import { AppSettings } from './../../app.settings';
import { BACKEND_URLS, USER_FUNCTIONS } from './../../constants';
import Utilities from 'src/app/helpers/utilities';
import { Program } from 'src/app/models/remote/program';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuestService {

  private _userToken: string;
  private _currentUser;
  private requestUrl: string = `${environment.api.host}/quest-user`
  private dotnetQuestUserUrl: string = `${BACKEND_URLS.dotNet}/quest-user`
  private adminRequestUrl: string = `${environment.api.host}/quest`
  private dotNetQuestUrl: string = `${BACKEND_URLS.dotNet}/quest`

  constructor(private httpClient: HttpClient) {
  }

  public getStudyOrders(tableRequestBody: TableRequestBody): Promise<any> {
    let requestURL =
      `${this.dotnetQuestUserUrl}/getStudyOrders?pageNum=${tableRequestBody.pageOffset}&perPage=${tableRequestBody.pageSize}`;
    if(tableRequestBody.filter)
      requestURL += `&filter=${tableRequestBody.filter}`;
    if(tableRequestBody.sortDir)
      requestURL += `&sortDir=${tableRequestBody.sortDir}`;
    if(tableRequestBody.sortField)
      requestURL += `&sortField=${tableRequestBody.sortField}`;

    return this.httpClient.get<any>(requestURL).toPromise()
  }
  public getStudyOrder(initialTreatmentObjectId: string): Promise<any> {
    let requestURL = `${this.dotnetQuestUserUrl}/getStudyOrder?initialTreatmentObjectId=${initialTreatmentObjectId}`;
    return this.httpClient.get<any>(requestURL).toPromise()
  }

  public localLogoutQuest() {
    this._userToken = '';
    this._currentUser = null;
    if (this.getLocalUserToken()) {
        localStorage.removeItem(AppSettings.authQuestTokenLocalStorageKey);
        // this.logoutQuest()
    }
  }

  public logoutQuest(): Promise<any> {

    const token = this.getLocalUserToken()
    return this.httpClient.post<any>(`${this.requestUrl}/logoutQuest`, {}).toPromise();
  }

  public forgotPasswordQuest(email): Promise<any> {
    return this.httpClient.post<any>(`${this.dotnetQuestUserUrl}/forgotPasswordQuest`, { email }).toPromise();
  }

  public resetQuestPasswordByCode(recoveryCode: number, newPassword: number): Promise<string> {
    return this.httpClient.post<any>(`${this.dotnetQuestUserUrl}/resetQuestPasswordByCode`, {recoveryCode, newPassword}).toPromise();
  }

  public invokeLoginQuest(data): Promise<any> {
    return this.httpClient.post<any>(`${this.dotnetQuestUserUrl}/loginQuest`, data).toPromise();
  }

  private processUserResponse(data): Promise<any> {
    try {
      localStorage.setItem(AppSettings.authQuestTokenLocalStorageKey, JSON.stringify(data));
      this._userToken = localStorage.getItem(AppSettings.authTokenLocalStorageKey);
      this._userToken = data['token'];
      delete data['token'];
      this._currentUser = data;
      return Promise.resolve(data);
    } catch (error) {
      throw {message: 'Tu navegador tiene las cookies deshabilitadas. Asegúrate de tener las cookies habilitadas y prueba de nuevo.'}
    }
  }

  public loginQuest(data): Promise<any> {
    return this.invokeLoginQuest(data).then(response => {
      return this.processUserResponse(response.user);
    }).catch(error => {
      throw error;
    });
  }

  public getLocalUserToken() {
    try {
      let localData: any;
      if (!this._userToken && localStorage.getItem(AppSettings.authQuestTokenLocalStorageKey)) {
          localData = JSON.parse(localStorage.getItem(AppSettings.authQuestTokenLocalStorageKey));
          this._userToken = localData.token;
      }
      return this._userToken;
    } catch(error) {
      throw {message: 'Tu navegador tiene las cookies deshabilitadas. Asegúrate de tener las cookies habilitadas y prueba de nuevo.'}
    }
  }
  public getLocalUser() {
    try {
      let localData: any;
      if (localStorage.getItem(AppSettings.authQuestTokenLocalStorageKey)) {
          localData = JSON.parse(localStorage.getItem(AppSettings.authQuestTokenLocalStorageKey));
          return localData
      }
    } catch(error) {
      throw {message: 'Tu navegador tiene las cookies deshabilitadas. Asegúrate de tener las cookies habilitadas y prueba de nuevo.'}
    }
  }


  // admin user functions
  public createQuestUser(data): Promise<any> {
    return this.httpClient.post<any>(`${this.dotNetQuestUrl}/createQuestUser`, data).toPromise();
  }

  public deleteQuestUser(objectId): Promise<any> {
    return this.httpClient.post<any>(`${this.dotNetQuestUrl}/deleteQuestUser`, { objectId }).toPromise();
  }
  public updateQuestUser(data): Promise<any> {
    return this.httpClient.post<any>(`${this.dotNetQuestUrl}/updateQuestUser`, data).toPromise();
  }
  public getQuestUsers(tableRequestBody): Promise<any> {
    let requestURL =
      `${this.dotNetQuestUrl}/getQuestUsers?pageNum=${tableRequestBody.pageOffset}&perPage=${tableRequestBody.pageSize}&laboratoryObjectId=${tableRequestBody.laboratoryObjectId}`;
    if(tableRequestBody.filter)
      requestURL += `&filter=${tableRequestBody.filter}`;
    if(tableRequestBody.sortDir)
      requestURL += `&sortDir=${tableRequestBody.sortDir}`;
    if(tableRequestBody.sortField)
      requestURL += `&sortField=${tableRequestBody.sortField}`;

    return this.httpClient.get<any>(requestURL).toPromise();
  }
  public getQuestUser(questUserObjectId): Promise<any> {
    return this.httpClient.get<any>(`${this.dotNetQuestUrl}/getQuestUser?questUserObjectId=${questUserObjectId}`).toPromise();
  }

  public generateQuestLogsReport(startDate: string, endDate: string, laboratoryObjectId: string): Promise<any> {
    let params = new HttpParams;
    // params = params.append('functionObjectId', USER_FUNCTIONS.generateItxReportsForAdmin);
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    params = params.append('laboratoryObjectId', laboratoryObjectId);
    return this.httpClient.get<any>(`${this.dotNetQuestUrl}/generateQuestLogsReport`, {params}).toPromise();
  }



}
